body {
  margin: 0;
  font-family: Arial, sans-serif;
}

header {
  position: fixed;
  width: 100%;
  top: 0;
  background: #292929;
  color: #fff;
  z-index: 1000;
  height: 60px; /* Fix the height */
  display: flex;
  align-items: center;
}

.header-content {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px; /* Ensure space between content and screen edge */
  box-sizing: border-box;
  height: 100%;
}

#home {
  background: #f4f4f4;
}
#products {
  background: #e0e0e0;
}

#features {
  background: #F5F5F5;
}

#features_component {
  background: #e0e0e0;
}

#about {
  background: #e0e0e0;
}

#blog {
  background: #e0e0e0;
}

#request-access {
  background: #f4f4f4;
}

.desktop-nav {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.desktop-nav li {
  margin: 0 0.8rem;
  cursor: pointer;
}

.desktop-nav li.active {
  font-weight: bold;
  border-bottom: 1.5px solid  #357ABD;
}

.desktop-nav li:hover {
  text-decoration: underline;
}

.mobile-nav {
  display: none;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
  padding: 0.5rem;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.logo {
  height: 33px;
}

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@800&display=swap');

.logo-text {
  font-family: 'Nunito', sans-serif;
  font-size: 1.65em !important; /* Set the specified size */
  color: #e5e3dd; /* Off-white color */
  text-decoration: none; /* Remove any underline or decoration */
  font-weight: 800; /* Bold weight */
  display: flex;
  align-items: center; /* Aligns items vertically */
}


.request-demo-button {
  background-color:  #357ABD;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.request-demo-button:hover {
  background-color: #1E5BCC;
}


.client-login-button {
  background-color:  #357ABD;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.client-login-button:hover {
  background-color: #1E5BCC;
}



footer {
  background: #292929;
  color: #fff;
  padding: 0.8rem 0;
  text-align: center;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
}

footer .logo-container {
  display: inline-block;
}

footer .logo {
  height: 30px;
  margin-bottom: 0rem;
  margin-top: 1rem;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin: 50px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}


/* Mobile Dropdown Menu */
.mobile-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  background: #292929;
  position: absolute;
  top: 60px;
  right: 20px;
  width: 150px;
  border: 1px solid #fff;
  border-radius: 4px;
}

.mobile-menu li {
  padding: 1rem;
  text-align: right;
  border-bottom: 1px solid #fff;
  cursor: pointer;
}

.mobile-menu li:last-child {
  border-bottom: none;
}

.mobile-menu li:hover {
  background: #1E5BCC;
}


.mobile-menu a {
  
  text-align: right;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
}


.mobile-menu a:hover {
  background: #1E5BCC;
}

/* Mobile Responsive Design */
@media (max-width: 768px) {
  .desktop-nav {
    display: none;
  }
  .loader {
    width: 40px;
    height: 40px;
    border-width: 6px;
  }
  
  .mobile-nav {
    display: block;
  }

  .search-icon {
    display: none;
  }

  .header-content {
    justify-content: space-between;
    padding: 0 30px; /* Add padding to ensure space from screen edge */
  }

  .logo-container {
    justify-content: flex-start;
  }

  .mobile-nav {
    padding: 0;
    align-self: flex-end;
    padding: 0 20px;
  }

  .mobile-menu {
    top: 60px;
    right: 20px;
  }
}
